/* eslint-disable react/jsx-one-expression-per-line,react/jsx-indent */
import React from 'react';
import SmartCarousel from '../components/SmartCarousel';
import impulseRefImage from '../assets/images/impulse/impulse-ref.png';
import impulseImage1 from '../assets/images/impulse/impulse-1.jpg';
import impulseImage2 from '../assets/images/impulse/impulse-2.jpg';
import impulseImage3 from '../assets/images/impulse/impulse-3.jpg';
import impulseImage4 from '../assets/images/impulse/impulse-4.jpg';
import impulseImage5 from '../assets/images/impulse/impulse-5.jpg';
import impulseImage6 from '../assets/images/impulse/impulse-6.jpg';
import impulseImage7 from '../assets/images/impulse/impulse-7.png';
import impulseImage8 from '../assets/images/impulse/impulse-8.png';
import impulseImage9 from '../assets/images/impulse/impulse-9.jpg';
import impulseImage10 from '../assets/images/impulse/impulse-10.jpg';
import impulseImage11 from '../assets/images/impulse/impulse-11.jpg';
import impulseImage12 from '../assets/images/impulse/impulse-12.jpg';
import impulseImage13 from '../assets/images/impulse/impulse-13.jpg';

import * as styles from '../css/impulse-details-content.css';
import PoweringImageContainer from '../components/PoweringImageContainer';
import PoweringImage from '../components/PoweringImage';
import { sectionIds } from './utils';
import DetailsHeaderText from '../components/DetailsHeaderText';
import DetailsText from '../components/DetailsText';
import Category from '../components/Category';
import SectionDetailsLink from '../components/SectionDetailsLink';
import ImportantText from '../components/ImportantText';

const ImpulseDetailsContent = () => (
    <>
        <DetailsHeaderText
            category="Stories"
            title="Supporting Electric Bus Operations - IMPULSE project"
        />
        <DetailsText>
            <ImportantText>IMPULSE: Driving Innovation for Electric Bus Operations in Public Transport</ImportantText>
            <p>
                The IMPULSE (Integrated Management of e-buses Power Utilization to Lead Sustainability and
                Electrification) project, supported by EIT Urban Mobility and co-funded by the European Union, aims
                to enhance realCity&apos;s traffic management software platform by introducing technical
                functionalities tailored for electric bus operations. Through two pilots in Lisbon, Portugal and the
                Princes’ Islands, Turkey, we have the opportunity to demonstrate capabilities in data collection,
                processing, and operational decision-making to support the future of sustainable urban mobility.
            </p>
            <ImportantText>Key outcomes:</ImportantText>
            <ul>
                <li>
                    Enhanced Traffic Management Platform: Integrating electric bus operation support, improving overall
                    fleet efficiency and sustainability.
                </li>
                <li>
                    Innovative Data Collection Methods: Developing scalable approaches for collecting vehicle data,
                    ranging from API integration to on-board units (OBUs) for manual or automated data input.
                </li>
                <li>
                    Improved Operational Insights: Extending realCity&apos;s analytics tools to include energy
                    consumption, battery status, and predictive capabilities for optimized fleet operations.
                </li>
                <li>
                    Advanced Prediction and Support Tools: Creating new backend components, such as the range predictor
                    engine and enhanced real-time dispatch tools, providing actionable insights and real-time
                    decision-making support.
                </li>
            </ul>

            <ImportantText>Technical Features Developed:</ImportantText>
            <ul>
                <li>
                    Data Collection: Supporting multiple methods, including APIs, CAN hardware integration, and manual
                    data input through our on-board unit software realCity | drive.
                </li>
                <li>
                    Data Processing and Storage: Enhancing realCity | replay for historical data analysis, energy
                    consumption tracking, and report generation.
                </li>
                <li>
                    Predictions and Operations Support: Leveraging realCity&apos;s new range predictor service and
                    realCity | core to process real-time information.
                </li>
                <li>
                    Optimizing fleet operations: Providing new overview tools and alerts for dispatchers in realCity |
                    dispatch to prevent service disruptions caused by premature battery depletion.
                </li>
            </ul>

            <ImportantText>Pilot cases:</ImportantText>
            <ol>
                <li className={styles.boldSection}>
                    <strong>Lisbon, Portugal</strong>
                    <ul>
                        <li>
                            Focusing on integrating data through various APIs provided by public transport operator, CARRIS.
                        </li>
                        <li>
                            Identifying patterns affecting energy consumption and introducing widgets to display data related to
                            average consumption and battery drainage per block.
                        </li>
                    </ul>
                </li>
                <li className={styles.boldSection}>
                    <strong>Princes Islands, Turkey</strong>
                    <ul>
                        <li>
                            Addressing challenges of manual data collection by developing an OBU interface for drivers to manually
                            input key operational data, supporting scenarios where direct vehicle integration is unavailable.
                        </li>
                        <li>
                            Extending predictive tools to adapt to manual data inputs while maintaining reliability.
                        </li>
                    </ul>
                </li>
            </ol>

            <SectionDetailsLink navigateTo={`/#${sectionIds.contactId}`}>
                Contact us
            </SectionDetailsLink>
        </DetailsText>
        <PoweringImageContainer>
            <PoweringImage className={styles.impulseLogo} gapSize="large" />
            <PoweringImage className={styles.eitLogo} gapSize="large" />
        </PoweringImageContainer>
        <SmartCarousel
            images={[
                impulseRefImage,
                impulseImage1,
                impulseImage2,
                impulseImage3,
                impulseImage4,
                impulseImage5,
                impulseImage6,
                impulseImage7,
                impulseImage8,
                impulseImage9,
                impulseImage10,
                impulseImage11,
                impulseImage12,
                impulseImage13,
            ]}
        />
        <Category centered>Partners</Category>
        <PoweringImageContainer>
            <PoweringImage className={styles.carnetLogo} />
            <PoweringImage className={styles.realCityLogo} />
            <PoweringImage className={styles.kadikoyLogo} />
            <PoweringImage className={styles.carrisLogo} />
        </PoweringImageContainer>
    </>
);

export default ImpulseDetailsContent;
